export const persons = [
  ["1", "1 persoon"],
  ["2", "2 personen"],
  ["3", "3 personen"],
  ["4", "4 personen"],
  ["5", "5 personen"],
  ["6", "6 personen"],
  ["7", "7 personen"],
  ["8", "8 personen"],
  ["9", "9 personen"],
  ["10", "10 personen"],
  ["11", "11 personen"],
  ["12", "12 personen"],
  ["13", "13 personen"],
  ["14", "14 personen"],
  ["15", "15 personen"],
  ["16", "16 personen"],
  ["17", "17 personen"],
  ["18", "18 personen"],
  ["19", "19 personen"],
  ["20", '20+ personen'],
  ["30", '30+ personen'],
  ["40", '40+ personen'],
  ["50", '50+ personen']
];