import { LitElement, html, css } from "lit";

import { normalize, openprops } from "./shared/openprops.css.js";

class MySelect extends LitElement {

  static get properties() {
    return {
      items: {
        type: Array,
      },
      label: {
        type: String,
      },
      modelValue: {
        type: String,
      }
    }
  }

  static get styles() {
    return css`
      ${normalize}
      ${openprops}
      select {
        appearance: none;
        background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0NTEuODQ3IDQ1MS44NDciIGFyaWEtaGlkZGVuPSJ0cnVlIj4KICAgIDxnPgogICAgICA8cGF0aCBkPSJNMjI1LjkyMywzNTQuNzA2Yy04LjA5OCwwLTE2LjE5NS0zLjA5Mi0yMi4zNjktOS4yNjNMOS4yNywxNTEuMTU3Yy0xMi4zNTktMTIuMzU5LTEyLjM1OS0zMi4zOTcsMC00NC43NTEKCQkJYzEyLjM1NC0xMi4zNTQsMzIuMzg4LTEyLjM1NCw0NC43NDgsMGwxNzEuOTA1LDE3MS45MTVsMTcxLjkwNi0xNzEuOTA5YzEyLjM1OS0xMi4zNTQsMzIuMzkxLTEyLjM1NCw0NC43NDQsMAoJCQljMTIuMzY1LDEyLjM1NCwxMi4zNjUsMzIuMzkyLDAsNDQuNzUxTDI0OC4yOTIsMzQ1LjQ0OUMyNDIuMTE1LDM1MS42MjEsMjM0LjAxOCwzNTQuNzA2LDIyNS45MjMsMzU0LjcwNnoiPjwvcGF0aD4KICAgIDwvZz4KICA8L3N2Zz4=)
          no-repeat 95% 20%;
        background-position-y: 50%;
        background-size: var(--size-2) var(--size-2);
        width: var(--size-13);
      }
      label {
        color: var(--label-color);
        display: var(--label-display-type);
      }


    `;
  }

  render() {
    return html`
      <label for="select">${this.label}</label>
      <select @change=${this._registerChange} id="select">
        ${this.items.map(
          (item) =>
            html`<option value=${item[0]} ?selected=${item[0] === this.modelValue}>
              ${item[1]}
            </option>`
        )}
      </select>
    `;
  }

  _registerChange = (event) => {
    this.modelValue = event.target.value;
    let customEvent = new CustomEvent('change', {
      detail: {
        message: 'Something important happened', bubbles: true, composed: true,

      },
    });
    this.dispatchEvent(customEvent);
  }
}

customElements.define("my-select", MySelect);
